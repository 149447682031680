import PlaceholderReact from 'components/atomic/PlaceholderReact'
import Breadcrumbs from '../atomic/Breadcrumbs'

const ImmagineHeader = ({ page, newPlaceholder, tabs, content }) => {
  const background = page.main_image
    ? { backgroundImage: `url(${page.main_image})` }
    : { backgroundColor: `black` }

  return (
    <main className="cms-page cms-page--immagine-header">
      <div className="w-container-fluid u-background-image immagine-header__top" style={background}>
        <div className="w-container">
          <Breadcrumbs breadcrumbs={page.breadcrumbs} />
        </div>
        <section className="immagine-header__header">
          <div className="w-container">
            <h1 className="immagine-header__title">{page.titolo}</h1>
            <p className="immagine-header__subtitle">{page.descrizione}</p>
          </div>
        </section>
      </div>

      <div className="immagine-header__content">
        <PlaceholderReact
          contentTypeId={page.model}
          objectId={page.id}
          pkey="content"
          serverSideContent={newPlaceholder}
        />
      </div>

      {page.tabs.length ? (
        <section className="formazione-page__tabs ">
          <div className="w-container-inner">
            <div className="row">
              <aside className="col col-md-3 formazione-page__navigation">{tabs}</aside>
              <section className="col col-md-9 formazione-page__content">{content}</section>
            </div>
          </div>
        </section>
      ) : null}
    </main>
  )
}

export default ImmagineHeader
